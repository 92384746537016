import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Gallery from './Gallery';

const Preview = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 2rem;
  border-top: 0.5px solid;
  padding-top: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 2fr 10fr;
  }
  header {
    h2 { 
      margin-top: 0;
      margin-bottom: 1rem;
    }
    p { margin-bottom: 0; }
  }
`;

const Info = styled.div`
  margin-top: 2rem;
  max-width: 300px; 
  > :first-child { margin-top: 0; }
  > :last-child { margin-top: 0; }
`;

class ProjectsPreview extends Component {

  constructor( props ) {
    super( props );
    this.state = {
      galleryImages: [],
    }
  }

  componentDidMount() {
    let galleryImages = [ this.props.post.frontmatter.image1 ];
    if ( this.props.post.frontmatter.image2 ) {galleryImages.push( this.props.post.frontmatter.image2 )}
    if ( this.props.post.frontmatter.image3 ) {galleryImages.push( this.props.post.frontmatter.image3 )}
    if ( this.props.post.frontmatter.image4 ) {galleryImages.push( this.props.post.frontmatter.image4 )}
    this.setState({
      galleryImages: galleryImages,
    })
  }

  render() {
    return (
      <Preview>
        <header>
          <h2>{this.props.post.frontmatter.title}</h2>
          <p>
            <small>{this.props.post.frontmatter.exhibition_dates}</small>
          </p>
        </header>
        <div>
          <Gallery images={this.state.galleryImages}/>
          <Info>
            <p>
              <small>{this.props.post.frontmatter.excerpt}</small>
            </p>
            <p>
              <small><Link to={this.props.post.frontmatter.path}>View project</Link></small>
            </p>
          </Info>
        </div>
      </Preview>
    )
  }
}

export default ProjectsPreview;