import React, { Component } from 'react';
import Layout from '../components/organisms/Layout';
import ProjectsPreview from '../components/organisms/ProjectsPreview';

class Projects extends Component {
  render() {
    return (
      <Layout>
        <article>
          {this.props.data.allMarkdownRemark.edges.map( edge => (
            <ProjectsPreview
              key={edge.node.id}
              post={edge.node}
            />
          ) )}
        </article>
      </Layout>
    )
  }
}

export default Projects;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex : "\/_posts/projects/"}
      }
      sort: {
        fields: [frontmatter___date]
        order: DESC
      }
    ){
      edges {
        node{
          id
          frontmatter {
            title
            date
            path
            address
            work
            image1
            image2
            image3
            image4
            exhibition_dates
            excerpt
          }
          html
        }
      }
    }
  }
`